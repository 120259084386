<template>
  <full-view :title="$t('app.route.managedStores')">
    <v-card flat>
      <center-content v-if="!notEmptyArray(getUserAssignedStores)">
        {{ $t("store.messages.noManagedStores") }}
      </center-content>

      <v-card-text class="overflow-y-auto px-0 pt-0" :style="getViewHeightStyle()">
        <v-list two-line class="pt-0">
          <div v-for="(store, i) in getUserAssignedStores" :key="i">
            <v-list-item link :class="i % 2 === 0 ? 'white' : 'grey lighten-5'">
              <v-list-item-avatar>
                <v-icon large>mdi-store</v-icon>
              </v-list-item-avatar>
              <v-list-item-content @click="navigateTo(store.id)">
                <v-list-item-title v-text="store.name" />
                <!-- <v-list-item-subtitle>{{
                  getAddressString(store.address)
                }}</v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </div>
        </v-list>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FullView from "@/common/layouts/FullView";
import CenterContent from "@/common/components/CenterContent";

export default {
  name: "ManagedStores",
  components: { FullView, CenterContent },
  props: {
    target: String,
  },
  mounted() {
    this.loadUserAssignedStores();
  },
  computed: {
    ...mapGetters("stores", ["getUserAssignedStores"]),
  },
  methods: {
    ...mapActions("stores", ["loadUserAssignedStores"]),

    navigateTo(storeId) {
      this.$router.push({
        name: this.target,
        params: { storeId, back: { name: this.$route.name } },
      });
    },

    getAddressString(a) {
      return `${a.title} / ${a.buildingNumber} ${a.street}, ${a.area ? a.area : ""} (
        ${a.latitude.toLocaleString({ minimumFractionDigits: 5 })} -
        ${a.longitude.toLocaleString({ minimumFractionDigits: 5 })})`;
    },
  },
};
</script>
