<template>
  <v-container>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-alert :color="color" :type="type" outlined dark>
          <slot></slot>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CenterContent",
  props: {
    type: {
      type: String,
      default() {
        return "info";
      },
    },
    color: {
      type: String,
      default() {
        return "secondary darken-1";
      },
    }
  }  
};
</script>
